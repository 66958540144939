<!--  -->
<template>
  <div class="page">
    <van-nav-bar
      class="backBox"
      title="续租延期"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="mainBox">
      <div class="monthBox" v-if="detail.monthOrGroup === 'month'">
        <div class="title boxItem">
          <div class="img">
            <img
              src="../../../assets/img/paymentRecordleft@2x.png"
              class="mainBoxLeftIcon"
            />
          </div>

          <span class="vhNum">{{ detail.plate_number }}</span>
        </div>
        <van-divider />
        <div class="nowDates boxItem">
          <div class="leftTit">当前月租时段：</div>
          <div class="rightValue">
            {{ timeFormatter(detail.create_time) }} -
            {{ timeFormatter(detail.effective_date_end) }}
          </div>
        </div>
        <div class="newDates boxItem">
          <div class="leftTit">续租时段：</div>
          <div class="rightValue">
            {{ showRentStartTime}} -
            {{ showRentEndTime }}
          </div>
        </div>
        <div class="newDates boxItem">
          <div class="leftTit">规则：</div>
          <div class="rightValue">
            {{detail.vh_cate_name}}：{{detail.month_rent / 100}}元/月
          </div>
        </div>
      </div>

      <div class="groupWrapBox" v-if="detail.monthOrGroup !== 'month'">
        <div class="groupBoxItem">
          <div class="names">
            <span class="groupName">{{ detail.groupName }} </span>
          </div>
          <div class="dates">
            当前月租时段：<span
              >{{ timeFormatter(detail.startDate) }} -
              {{ timeFormatter(detail.endDate) }}</span
            >
          </div>
          <div class="plates">
            <span
              v-for="(plateItem, plateIndex) in detail.vhPlates"
              :key="plateIndex"
              class="platesShowItem"
              >{{ plateItem }}</span
            >
          </div>

          <div class="showWhenSelGroup">
            <van-divider />
            <div class="monthFooter">
              <div class="leftTit">续租月数</div>
              <div class="">
                <van-stepper v-model="monthNum" integer />
              </div>
            </div>
            <div class="monthFooter monthFooterDate">
              <div class="leftTit">续租时间</div>
              <div class="rightValue">
                <!-- {{ countMonthDate(detail) }} -->
              </div>
            </div>
            <van-divider />
          </div>
        </div>
      </div>
      <div class="boxItem otherItem">
        <span class="leftTit">续租月数</span>
        <div class="">
          <van-stepper v-model="monthNum" integer @change="onChangeStep" />
        </div>
      </div>
      <div class="boxItem otherItem">
        <div class="leftTit">共计</div>
        <div class="rightMoney">
          {{ payMoney }} <span class="rightValue">元</span>
        </div>
      </div>

      <div class="btnOperation">
        <van-button @click="onClickPay" class="query_btn" :loading="payBtnLoading" loading-text="加载中...">
          <span class="query_btn_text">确认支付</span>
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import * as common from '../../../api/common'
import * as api from '../../../api/api'
import * as util from '../../../api/util'
import * as constant from '../../../api/constant'
import { mapState, mapGetters } from 'vuex'
import CarKeyboard from '../../../components/CarKeyboard.vue'
import { Dialog, Toast } from 'vant'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      detail: {},
      monthNum: 1,
      payMoney: 0,
      defaultRent: '', // 默认月租金
      payBtnLoading: false,
      isVueReady: false,
      isTypeReady: false,
      isPress: false,
      showRentStartTime: '',
      showRentEndTime: '',
      tempBack: {
        expireMillSeconds: 240 * 1000,
        timeout: null,
        timestamp: 0
      },
      code: ''
    }
  },
  // 监听属性 类似于data概念
  computed: {
    ...mapGetters(['vhColumns', 'openID', 'thePayType']),
    ...mapState({
      // payType: state => state.payType.payType,
      sid: state => state.sid.sid,
      appid: state => state.appid.appid
    })
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 计算续租时段的展示
    countRentDate (eDate) {
      console.log('计算续租时段的展示')
      const endTime = util.countRentTimeShow(eDate, this.monthNum)
      // console.log('计算后的续租结束日期', this.timeFormatter(endTime))
      console.log('计算后的续租结束日期', endTime)
      this.showRentStartTime = util.getNextDay(eDate)
      this.showRentEndTime = endTime
    },
    getTempBackTimeout () {
      const ms = this.tempBack.expireMillSeconds
      if (!this.tempBack.timestamp) this.tempBack.timestamp = new Date().getTime()
      // console.log('tempBack.timestamp', this.tempBack.timestamp)
      this.tempBack.timeout = setTimeout(() => {
        this.expireGoBack()
        this.clearBackTimeout()
      }, ms)
    },
    clearBackTimeout () {
      clearTimeout(this.tempBack.timeout)
      this.tempBack.timeout = null
    },
    expireGoBack () {
      this.tempBack.timestamp = 0
      this.$dialog.alert({
        title: '支付超时',
        message: '点击“确认”后重新付款'
      }).then(() => {
        // this.setDataWhenErrorConfirm()
        this.onClickLeft()
      })
    },
    clearSessionData () {
      sessionStorage.removeItem(constant.keyBusinessInfoSession)
    },
    setButtonClertload () {
      this.$nextTick(() => {
        this.isTypeReady = true
      })
    },
    wxReadyToPay (payargs) {
      console.log('wxReadyToPay---', payargs)
      var that = this
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          // console.log('vv 111')
          document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady(payargs), false)
        } else if (document.attachEvent) {
          // console.log('vv 222')
          document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady(payargs))
          document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady(payargs))
        }
      } else {
        // console.log('vv 333')
        that.onBridgeReady(payargs)
      }
    },
    onBridgeReady (payargs) {
      console.log('onBridgeReady---', payargs)
      console.log('onBridgeReady---', this.thePayType, typeof this.thePayType)
      var that = this
      // return
      const payType = Number(this.thePayType)
      if (payType === common.payType.wx) { // 微信直联
        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            appId: payargs.appId != null ? payargs.appId : that.appid, // 公众号名称，由商户传入
            timeStamp: payargs.timeStamp || payargs.timestamp, // 时间戳，自1970年以来的秒数
            nonceStr: payargs.nonceStr || payargs.nonce_str, // 随机串
            package: payargs.package,
            signType: payargs.signType != null ? payargs.signType : 'MD5', // 微信签名方式：
            paySign: payargs.paySign || payargs.sign // 微信签名
          },
          function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              // that.$toast.success('支付完成')
              // // that.$router.replace('/index')
              // that.$router.replace({
              //   path: '/index',
              //   query:{
              //     sid: this.$Storejs.getItem('sid'),
              //     channel_path: this.$Storejs.getItem('channel_path')
              //   }
              // })
              // that.$toast({
              //   type: 'success',
              //   message: '支付完成',
              //   duration: 0,
              //   forbidClick: true
              // })
              window.location.replace('https://pa03.yuntingiot.com/api/ac/477786592149311488')
              // WeixinJSBridge.call('closeWindow')
            } else {
              that.$dialog.alert({
                // showCancelButton: true,
                title: '支付失败'
              }).then(() => {
                // that.onClickLeft()
                that.setDataWhenErrorConfirm()
              })
            }
          })
      } else if (payType === common.payType.bjyz) { // 微信云纵间联
        // const pars = {
        //   start_time: this.start_time,
        //   hour: this.hour,
        //   min: this.min,
        //   seconds: this.seconds,
        //   actually_paid: this.actually_paid,
        //   couponPrice1: this.couponPrice1,
        //   invoice_url: payargs.invoice_url,
        //   sid: this.sid,
        //   parkName: this.parkName,
        //   plateNumber: this.plate_number
        // }
        // this.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true
        window.location.replace(payargs.trade_url)
        //            window.location.href = payargs.trade_url;
      } else if (payType === common.payType.pp) { // 微信PP间联
        console.log('wx pp', payargs)
        // TODO 保存月租相关的支付完成信息  等支付完成跳转展示。如果支付平台没有支付完成展示页面则需要展示
        const pars = {
          start_time: this.start_time,
          actually_paid: this.actually_paid,
          couponPrice1: this.couponPrice1,
          sid: this.sid,
          parkName: this.parkName,
          plateNumber: this.plate_number
        }

        this.$store.commit(constant.keyPayMonthCompleteInfoVuex, pars)

        this.isPress = true
        this.payBtnLoading = false
        window.location.replace(payargs.pay_url)
        // pay_url: "https://auth.4pyun.com/authorize?scope=snsapi_base&redirect_uri=https%3A%2F%2Fapp.4pyun.com%2Fpayment%2Ftrade%2Fcreate%3Fpay_id%3D983768955900399617"

        //            window.location.href = payargs.trade_url;
      } else if (payType === common.payType.abc) { // 微信农行间联
        // const pars = {
        //   start_time: this.start_time,
        //   hour: this.hour,
        //   min: this.min,
        //   seconds: this.seconds,
        //   actually_paid: this.actually_paid,
        //   couponPrice1: this.couponPrice1,
        //   sid: this.sid,
        //   parkName: this.parkName,
        //   plateNumber: this.plate_number
        // }

        // that.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true

        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            appId: payargs.appId != null ? payargs.appId : that.appid, // 公众号名称，由商户传入
            timeStamp: payargs.timeStamp || payargs.timestamp, // 时间戳，自1970年以来的秒数
            nonceStr: payargs.nonceStr || payargs.nonce_str, // 随机串
            package: payargs.package,
            signType: payargs.signType != null ? payargs.signType : 'MD5', // 微信签名方式：
            paySign: payargs.paySign || payargs.sign // 微信签名
          },
          function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              // that.$toast.success('支付完成')
              // // that.$router.replace('/index')
              // that.$router.replace({
              //   path: '/index',
              //   query:{
              //     sid: this.$Storejs.getItem('sid'),
              //     channel_path: this.$Storejs.getItem('channel_path')
              //   }
              // })
              // that.$toast({
              //   type: 'success',
              //   message: '支付完成',
              //   duration: 0,
              //   forbidClick: true
              // })
              window.location.replace('https://pa03.yuntingiot.com/api/ac/477786592149311488')
              // WeixinJSBridge.call('closeWindow')
            } else {
              that.$dialog.alert({
                // showCancelButton: true,
                title: '支付失败'
              }).then(() => {
                // that.onClickLeft()
                that.setDataWhenErrorConfirm()
              })
            }
          })
      } else if (payType === common.payType.lanzhoubank) { // 兰州银行微信
        // const pars = {
        //   start_time: this.start_time,
        //   hour: this.hour,
        //   min: this.min,
        //   seconds: this.seconds,
        //   actually_paid: this.actually_paid,
        //   couponPrice1: this.couponPrice1,
        //   sid: this.sid,
        //   parkName: this.parkName,
        //   plateNumber: this.plate_number
        // }

        // that.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true
        window.location.replace(payargs.billQRCode)
      } else if (payType === common.payType.ningbobank) { // 宁波银行微信
        // const pars = {
        //   start_time: this.start_time,
        //   hour: this.hour,
        //   min: this.min,
        //   seconds: this.seconds,
        //   actually_paid: this.actually_paid,
        //   couponPrice1: this.couponPrice1,
        //   sid: this.sid,
        //   parkName: this.parkName,
        //   plateNumber: this.plate_number
        // }

        // this.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true
        window.location.replace(payargs.pay_url)
      }
    },
    WX_Bridge_ready (cb) {
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', cb(), false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', cb())
          document.attachEvent('onWeixinJSBridgeReady', cb())
        }
      } else {
        cb()
      }
    },
    doPay () {
      const that = this
      Toast.loading({
        forbidClick: true,
        message: '请稍候...'
      })
      const sessionStorageLocal = JSON.parse(sessionStorage.getItem(constant.keyBusinessInfoSession))
      const grossPrice = parseFloat(this.payMoney) * 100
      const actuallyPaid = parseFloat(this.payMoney) * 100
      const subject = sessionStorageLocal.plate_number + sessionStorageLocal.vh_cate_name + '服务续费'
      // TODO
      common.getPayargsMonth(this.sid, grossPrice, actuallyPaid, Number(this.thePayType), subject, sessionStorageLocal, this.code)
        .then(payargs => {
          that.$toast.clear()
          that.isPress = false
          console.log('getPayargsMonth----', payargs)
          if (!payargs) {
            that.$dialog.alert({
              title: '支付失败'
            }).then(() => {
              that.onClickLeft()
              // that.setDataWhenErrorConfirm()
            })
            return
          }
          if (common.getUserAgent() === common.userAgentInfo.wx) {
            that.wxReadyToPay(payargs)
          } else if (common.getUserAgent() === common.userAgentInfo.zfb) {
            that.do_zfb_pay(payargs)
          }
        })
        .catch(e => {
          console.log('获取支付平台res', e)
          that.$toast.clear()
          that.isPress = false
          if (parseFloat(grossPrice) > 0) {
            console.log(e)
            that.$dialog.alert({
              // showCancelButton: true,
              title: '支付失败',
              message: e
            }).then(() => {
              that.onClickLeft()
              // that.setDataWhenErrorConfirm()
            })
          } else that.$toast.clear()
        })
    },
    setDataWhenErrorConfirm () {
      this.payBtnLoading = false
      const sessionStorageLocal = JSON.parse(sessionStorage.getItem(constant.keyBusinessInfoSession))

      console.log('获取到的code：', this.code)
      console.log('获取到的code：', this.$route.query.code)
      // TODO 点击支付获取code后会重新来到该页面 走挂载，点击支付后需要设置是否点击了支付按钮的标志，挂载中判断是否已经点击，
      // todo 已经点击，且有code，则直接访问支付接口获取支付回调地址。否则不执行
      if (sessionStorageLocal) {
      // 数据回显
        this.detail = sessionStorageLocal
        // this.plateNumber = sessionStorageLocal.plate_number.split('')
        // this.monthNum = Number(sessionStorageLocal.month)
        // this.payMoney = Number(sessionStorageLocal.monthly_rent_fee) / 100 * this.monthNum
        //  create_time: this.detail.create_time,
        // effective_date_end: this.detail.effective_date_end
      }
    },
    // 购买数量
    onChangeStep () {
      console.log('购买数量', this.monthNum)
      this.countMoney(this.defaultRent, this.monthNum)
      this.countRentDate(this.detail.effective_date_end)
    },
    countMoney (money, month) {
      this.payMoney = money * month
    },
    async onClickPay () {
      if (!this.sid) {
        return Toast.fail('车场编号丢失，请返回重试')
      }
      if (this.payMoney <= 0) {
        return Toast.fail('应付金额为0')
      }
      if (!this.isVueReady) {
        return this.$toast('努力加载中.')
      } else if (!this.isTypeReady) {
        if (common.getUserAgent() === common.userAgentInfo.wx) {
          return this.$toast('微信努力加载中.')
        } else {
          return this.$toast('支付宝努力加载中.')
        }
      }
      this.payBtnLoading = true
      Toast.loading({
        forbidClick: true,
        message: '请稍候...'
      })
      // let payWay = common.payWay.weichat
      // if (common.getUserAgent() === 'wx') {
      //   payWay = common.payWay.weichat
      // } else if (common.getUserAgent() === 'zfb') {
      //   payWay = common.payWay.alipay
      // }
      const businessInfo = {
        business_type: constant.monthRentInfos.businessType,
        charge_type: constant.monthRentInfos.chargeType.vhRenew,
        plate_number: this.detail.plate_number,
        vh_cate_id: Number(this.detail.vh_cate_id),
        vh_type_id: 1,
        month: this.monthNum,
        charge_start_time: util.getNextDayStart(this.detail.effective_date_end),
        monthly_rent_fee: Number(this.detail.month_rent),
        month_number: this.monthNum,
        create_time: this.detail.create_time,
        effective_date_end: this.detail.effective_date_end
      }
      sessionStorage.setItem(constant.keyBusinessInfoSession, JSON.stringify(businessInfo))
      // sessionStorage.setItem(constant.keyPayTypeSession, this.thePayType)
      // 微信或支付宝 code, type=1、2、41 时必须获取上传 code
      const comparePayType = Number(this.thePayType)

      if (comparePayType === common.payType.wx || comparePayType === common.payType.zfb || comparePayType === common.payType.abc) {
        // 需要code
        // 获取code
        this.getAllCode()
      } else {
        this.code = null
        this.doPay()
      }
    },
    getAllCode () {
      if (common.getUserAgent() === common.userAgentInfo.wx) {
        console.log('wx getcode')
        this.getAppId().then(appid => {
          window.location.href = common.getCodeObtainUrl(window.location, appid, true)
          console.log('window.location.href', window.location.href)
        }).catch(e => {
          this.$toast('网络错误')
        })
      }
      // console.log('22222222222222')
    },
    async getAppId () {
      return axios({
        url: common.cloudParkingUrl + '/s2p/' + api.urlList.query_wx_appid,
        method: 'POST',
        data: {
          sid: this.sid || this.$route.query.sid
          // sid: this.sidLocal
        },
        header: {
          'content-type': 'application/json' // 默认值
        }
      }).then(res => {
        const d = res && res.data
        if (d.code === 10000) {
          // console.log('getAppId', d)
          this.appidLocal = d.app_id
          // this.$store.commit(constant.keyAppIDVuex, d.app_id)
          return this.appidLocal
        } else {
          return Promise.reject(new Error())
        }
      })
    },
    // 根据续租月数，更新续租时间
    countMonthDate (item) {
      console.log('续租', item)
      console.log('续租月数', this.monthNum)
      if (!item.startDate || !item.endDate) {
        return this.$toast('开始日期或结束日期不规范')
      }
      // 将startDate字符串转换为Date对象
      const startDate = new Date(item.startDate)
      const endDate = new Date(item.endDate)

      // 添加续租月数
      endDate.setMonth(endDate.getMonth() + this.monthNum)
      // 如果endDate小于startDate（考虑跨年情况），则增加年份
      if (endDate < startDate) {
        endDate.setFullYear(endDate.getFullYear() + 1)
      }
      // 将endDate转换回字符串格式
      const endDateStr = endDate.toISOString().split('T')[0] // ISO格式，日期部分

      return item.startDate + ' - ' + endDateStr
    },
    timeFormatter (para) {
      return common.format(new Date(para), 'yyyy-MM-dd')
    },
    onClickLeft () {
      this.$router.go(-1)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.isVueReady = true
    console.log('当前介质是微信还是支付宝', common.getUserAgent())
    this.setButtonClertload()
    if (common.getUserAgent() === common.userAgentInfo.wx) {
      this.WX_Bridge_ready(() => {
        this.setButtonClertload()
      })
    } else if (common.getUserAgent() === common.userAgentInfo.zfb) {
      this.ZFBReady(() => {
        this.setButtonClertload()
      })
    }
    // this.$nextTick(() => {})

    // 判断是否需要进行支付
    const sessionStorageLocal = JSON.parse(sessionStorage.getItem(constant.keyBusinessInfoSession))
    const detailObj = JSON.parse(sessionStorage.getItem('postponeDetail'))

    // const payTypeLocal = sessionStorage.getItem(constant.keyPayTypeSession)
    // console.log('payTypeLocal', payTypeLocal)
    console.log('this.thePayType', this.thePayType)
    console.log('this.sid', this.sid)
    console.log('this.openID', this.openID)

    // this.code = this.$route.query.code
    const comparePayType = Number(this.thePayType)

    const needCode = (comparePayType === common.payType.wx || comparePayType === common.payType.zfb || comparePayType === common.payType.abc)
    // 需要code
    // 获取code

    if (sessionStorageLocal && needCode) {
      this.$dialog.alert({
        title: '打印当前URL',
        message: window.location.href
      }).then(() => {
      })
      // if (this.$route.query) {
      this.detail = detailObj
      this.detail.monthOrGroup = detailObj.monthOrGroup

      // if (common.getUserAgent() === common.userAgentInfo.wx) {
      const urlParams = new URLSearchParams(window.location.search)
      this.code = urlParams.get('code') || this.$route.query.code
      // this.code = this.$route.query.code
      // }
      // }
      // 数据回显
      // this.plateNumber = sessionStorageLocal.plate_number.split('')
      // this.radioSelVal = Number(sessionStorageLocal.vh_cate_id)
      // this.monthNum = Number(sessionStorageLocal.month)
      // this.waitPayMoney = Number(sessionStorageLocal.monthly_rent_fee)
      // this.payTypeLocal = payTypeLocal
      // this.vh_cate_name = this.detail.vh_cate_name
      this.monthNum = sessionStorageLocal.month
      this.payMoney = Number(this.detail.month_rent) / 100 * sessionStorageLocal.month

      this.countMoney(Number(this.detail.month_rent) / 100, this.monthNum)
      this.countRentDate(this.detail.effective_date_end)

      // TODO 已经点击支付且code成功拿回 可以请求支付接口
      // this.$dialog.alert({
      //   title: '打印当前code',
      //   message: this.code
      // }).then(() => {
      // })
      if (this.code) {
        // TODO 已经点击支付且code成功拿回 可以请求支付接口
        Toast.loading({
          forbidClick: true,
          message: '请稍候...'
        })
        this.doPay()
      } else {
        // this.getAllCode()
        this.$dialog.alert({
          title: '支付失败',
          message: 'code失效'
        }).then(() => {
          this.onClickLeft()
        })
      }
      return
    }
    console.log('接受到的参数', this.$route.query)

    this.detail = detailObj
    this.detail.monthOrGroup = detailObj.monthOrGroup

    console.log('接受到的参数detail', this.detail)
    const defaultRent = this.detail.month_rent / 100 // 默认月租金
    this.defaultRent = defaultRent
    this.countMoney(defaultRent, 1)
    this.countRentDate(this.detail.effective_date_end)
    this.isVueReady = true
    if (common.getUserAgent() === common.userAgentInfo.wx) {
      this.WX_Bridge_ready(() => {
        this.setButtonClertload()
      })
    } else if (common.getUserAgent() === common.userAgentInfo.zfb) {
      this.ZFBReady(() => {
        this.setButtonClertload()
      })
    }
    this.getTempBackTimeout()
  },
  beforeRouteLeave (to, from, next) {
    // 调用方法清除 session 数据
    if (to.path === '/reletPostpone') {
      this.clearSessionData()
      sessionStorage.removeItem('postponeDetail')
    }
    // 继续路由导航
    next()
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.path === '/reletPostpone') {
        vm.clearSessionData()
      }
    })
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后

  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
.page {
  background-color: #f8f7fc;
}
.mainBox {
  /deep/ .van-divider {
    margin: 0;
  }
  .boxItem {
    display: flex;
    justify-content: space-between;
    padding: 0.1rem 0;
  }
  .otherItem{
    padding: 0.1rem;
    margin-top: 0.16rem;
    background-color: #fff;
    display: flex;
    align-items: center;
  }
  .monthBox {
    padding: 0.16rem;
    background-color: #fff;

    .title {
      font-size: 0.16rem;
      color: #333;
      line-height: 0.22rem;
      justify-content: flex-start;

      .mainBoxLeftIcon {
        width: 0.18rem;
        height: 0.14rem;
        // margin: 0.03rem 0 0.43rem 0;
      }
      .vhNum {
        margin-left: 0.05rem;
      }
      // .img img{
      //   width: 100%;
      //   height: 100%;
      // }
    }
    .nowDates {
      // margin-top: 0.1rem;
      padding: 0.1rem 0;

    }
  }
  .leftTit {
    font-size: 0.12rem;
    color: #999;
  }
  .rightValue {
    font-size: 0.12rem;
    color: #333;
  }
  .rightMoney {
    font-size: 0.18rem;
    color: #333;
  }
  // 分组车
  .groupWrapBox {
    margin-top: 0.1rem;
    .groupBoxItem {
      background-color: #fff;
      padding: 0.06rem 0.16rem 0.16rem;
      margin-bottom: 0.16rem;
      .names {
        display: flex;
        .groupName {
          font-size: 0.12rem;
          color: #333;
          font-weight: bold;
          line-height: 0.2rem;
          margin-left: 0.06rem;
        }
      }

      .dates {
        font-size: 0.14rem;
        line-height: 0.17rem;
        padding-left: 0.22rem;
        text-align: left;
      }
      .plates {
        display: flex;
        margin-top: 0.1rem;
        margin-bottom: 0.06rem;
        padding-left: 0.22rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .platesShowItem {
          padding: 0.05rem 0.1rem;
          color: #000;
          font-size: 0.1rem;
          margin-right: 0.1rem;
          margin-bottom: 0.1rem;
          background-color: #f4f4f4;
          border-radius: 0.12rem;
        }
      }
      .showWhenSelGroup {
        .monthFooter {
          display: flex;
          justify-content: space-between;
          margin-top: 0.08rem;
          .leftTit {
            font-size: 0.12rem;
            color: #999;
          }
        }
        .monthFooterDate {
          margin-top: 0.1rem;
          margin-bottom: 0.1rem;
          .rightValue {
            font-size: 0.12rem;
            color: #333;
          }
        }
      }
    }
  }
  .btnOperation {
    display: flex;
    justify-content: center;
    .query_btn {
      width: 100%;
      background-color: #4797ea;
      border-radius: 0.04rem;
      margin-top: 0.3rem;
      // display: flex;
      // flex-direction: column;
      // padding: 0.1rem 1.21rem 0.1rem 1.21rem;
      margin-left: 0.16rem;
      margin-right: 0.16rem;
      .query_btn_text {
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 0.16rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 0.22rem;
      }
    }
  }
}
</style>
